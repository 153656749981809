.google_play_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffffff;
    border-radius: 24px;
    background-color: #ffffff;
    padding: 0.525rem 2rem;
    text-align: center;
    outline: 0;
    transition: all .2s ease;
    text-decoration: none;
  }
  
.google_play_btn:hover {
    background-color: transparent;
    color: rgb(9, 9, 9);
}

.app_store_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffffff;
    border-radius: 24px;
    background-color: #ffffff;
    padding: 0.525rem 2rem;
    text-align: center;
    outline: 0;
    transition: all  .2s ease;
    text-decoration: none;
  }
  
  .app_store_btn:hover {
    background-color: transparent;
    color: rgb(9, 9, 9);
  }
  
  .icon {
    width: 3em;
  }
  
  .texts {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    line-height: 35px;
  }
  
  .text-1 {
    margin-bottom: 0.25rem;
    font-weight: 600;
  }
  
  .text-2 {
    font-weight: 600;
  }

  @media (max-width:1512px) {
    .texts {
       font-size: 17px;
       line-height: 25px;
    }
    .icon {
      width: 2em;
    }
  }

  @media (max-width:640px) {
    .google_play_btn{ 
      border-radius: 24px;
    }
    .app_store_btn{ 
      border-radius: 24px;
    }
    .texts {
       line-height: 20px;
    }
    .texts {
      font-size: 15px;
    }
  }
  @media (max-width:500px) {
    .icon {
        width: 1.5rem;
    }
    .app_store_btn {        
        padding: 0.325rem 1.5rem;
    }
    .google_play_btn {        
        padding: 0.325rem 1.5rem;   
    }
    .text-1 {
      margin-bottom: 0;
    }
  }
  @media (max-width:425px) {
    .texts {
        font-size: 10px;
        line-height: 12px;
    }
    .app_store_btn {        
        padding: 0.225rem 1rem;
    }
    .google_play_btn {        
        padding: 0.225rem 1rem;   
    }
    .google_play_btn{ 
      border-radius: 12px;
    }
    .app_store_btn{ 
      border-radius: 12px;
    }
  }
  @media (max-width:375px) {
    .texts {
        font-size: 8px;
    }
    .icon {
        width: 1.2rem;
    }
    .app_store_btn {        
        padding:0.1 1rem;
    }
    .google_play_btn {        
        padding:0.1 1rem;   
    }
  }
  @media (max-width:320px) {
    #welcome {
      padding-bottom: 0;
    }
  }